import { ResponsiveImageSize } from '@loveholidays/design-system';
import { SystemStyleObject } from '@styled-system/css';
import React, { forwardRef } from 'react';

import { ClassNameProps } from '@ComponentProps';

interface CardProps extends ClassNameProps {
  imageArea: React.ReactNode;
  headerArea: React.ReactNode;
  bodyArea: React.ReactNode;
  infoArea: React.ReactNode;
  actionArea: React.ReactNode;
  priceArea: React.ReactNode;
  subActionArea?: React.ReactNode;
}

// Mobile width is dynamic. The mobile width here is not to be used to size the content on screen.
// Instead, it is used as an approximation from which we work out the best image size to download.
export const imageAreaWidths: [number, number, number] = [327, 300, 400];
export const imageAreaHeights: ResponsiveImageSize = [200, 200, 298];

const commonAreaStyles: SystemStyleObject = {
  // Absolutely positioned children within the area should be relative to the area.
  position: 'relative',
};

export const Card = forwardRef<HTMLElement, CardProps>(
  (
    {
      'data-id': dataId,
      imageArea,
      headerArea,
      bodyArea,
      infoArea,
      actionArea,
      priceArea,
      subActionArea,
      className,
    },
    ref,
  ) => {
    const desktopGridTemplateAreas = subActionArea
      ? `
  "image headers headers headers subAction"
  "image body    price   price   price"
  "image info    info    info    info"
  "image action  action  action  action"`.replace(/\s+/g, ' ')
      : `
      "image headers headers headers"
      "image body    body    price"
      "image info    info    info"
      "image action  action  action"`.replace(/\s+/g, ' ');

    return (
      <section
        data-id={dataId}
        ref={ref}
        className={className}
        sx={{
          display: 'grid',
          gridTemplateAreas: [
            '"image" "headers" "body" "info" "price" "action"',
            subActionArea
              ? `
          "image  headers headers subAction"
          "image  body    price   price"
          "info   info    info    info"
          "action action  action  action"
      `.replace(/\s+/g, ' ')
              : `
          "image  headers headers"
          "image  body    price"
          "info   info    info"
          "action action  action"
      `.replace(/\s+/g, ' '),
            desktopGridTemplateAreas,
          ],
          gridTemplateColumns: [
            null,
            `${imageAreaWidths[1]}px 1fr auto min-content`,
            `${imageAreaWidths[2]}px 1fr auto auto min-content`,
          ],
          backgroundColor: 'backgroundWhite',
          borderRadius: '12',
          borderColor: 'strokeLightneutral',
          borderStyle: 'solid',
          borderWidth: 'outlinedStrokeWeight',
          overflow: 'hidden',
          position: 'relative',
          '& > div': {
            borderWidth: 'outlinedStrokeWeight',
            borderColor: 'strokeLightneutral',
          },
        }}
      >
        <div
          sx={{
            ...commonAreaStyles,
            gridArea: 'image',
            minHeight: imageAreaHeights,
          }}
        >
          {imageArea}
        </div>
        <div
          sx={{
            ...commonAreaStyles,
            gridArea: 'headers',
            padding: 's',
            paddingBottom: ['xs', 0],
            paddingTop: [null, 'xs'],
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '3xs',
          }}
        >
          {headerArea}
        </div>
        <div
          sx={{
            ...commonAreaStyles,
            gridArea: 'body',
            display: 'flex',
            flexWrap: 'wrap',
            paddingLeft: 's',
            paddingBottom: 'xs',
            paddingTop: [0, '2xs'],
            alignItems: [null, 'flex-end'],
            gap: '3xs',
            minWidth: 0,
          }}
        >
          {bodyArea}
        </div>
        <div
          sx={{
            ...commonAreaStyles,
            gridArea: 'info',
            borderTopStyle: 'solid',
            display: 'flex',
            alignItems: [null, 'center'],
            flexWrap: [null, 'wrap'],
            flexDirection: ['column', 'row'],
            gap: '2xs',
            paddingY: ['xs', 's'],
            paddingRight: ['s', 'xs'],
            paddingLeft: 's',
          }}
        >
          {infoArea}
        </div>
        <div
          sx={{
            ...commonAreaStyles,
            gridArea: 'action',
            display: ['grid', null],
            gridAutoColumns: '1fr',
            gridAutoFlow: 'column',
            alignItems: 'stretch',
            alignContent: 'stretch',
            gap: ['2xs', '3xs'],
            padding: 's',
            paddingTop: ['3xs', 0, null],
            paddingBottom: [null, 'xs', '2xs'],
            paddingRight: [null, 'xs'],
          }}
        >
          {actionArea}
        </div>
        <div
          sx={{
            ...commonAreaStyles,
            gridArea: 'price',
            display: 'flex',
            flexDirection: ['row', 'column'],
            alignItems: ['center', 'flex-end'],
            gap: ['2xs', '3xs'],
            justifyContent: 'flex-end',
            borderTopStyle: ['solid', 'none'],
            paddingX: ['s', 'xs'],
            paddingTop: ['xs', 0],
            paddingBottom: [null, '2xs'],
          }}
        >
          {priceArea}
        </div>
        {subActionArea && (
          <div
            sx={{
              ...commonAreaStyles,
              position: ['absolute', 'relative'],
              gridArea: [null, 'subAction'],
              right: 0,
              top: 0,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              margin: ['3xs', 'xs'],
            }}
          >
            {subActionArea}
          </div>
        )}
      </section>
    );
  },
);
