import { useTranslation } from '@loveholidays/phrasebook';
import React from 'react';

import { Accommodation, Badge, OfferFlights, Pricing, ProductTrackingList } from '@AuroraTypes';
import { ClassNameProps } from '@ComponentProps';
import { Button } from '@Components/Button/Button';
import { useAppContext } from '@Contexts/contexts';
import { useFeatureFlag } from '@Core/octopus/useFeatureFlag';
import { useProductClickTracking } from '@Tracking';

export interface CheckAvailabilityButtonProps extends ClassNameProps {
  url: string;
  pricing: Pick<Pricing, 'total'>;
  accommodation: Pick<Accommodation, 'id' | 'name' | 'images'>;
  position: number;
  actionList: ProductTrackingList;
  badges: Badge[];
  flights: OfferFlights;
}

export const CheckAvailabilityButton: React.FC<CheckAvailabilityButtonProps> = ({
  url,
  accommodation,
  pricing,
  position,
  actionList,
  className,
  badges,
  flights,
}) => {
  const { t } = useTranslation();
  const trackProductClick = useProductClickTracking();
  const { isWebView } = useAppContext();
  // Disable the linting rule here as we don't want to send the experiment event in app
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const openInNewTab = !isWebView && useFeatureFlag('OpenPandaInNewTab');

  return (
    <Button
      data-id="check-availability-button"
      variant="Primary"
      size="36"
      target={openInNewTab ? '_blank' : undefined}
      href={url}
      asAnchor
      internal
      className={className}
      onClick={() =>
        trackProductClick(
          [
            {
              masterId: accommodation.id,
              name: accommodation.name,
              price: pricing.total,
              hasImages: !!accommodation.images.count,
              position,
              refundablePackage: !!badges.some(({ type }) => type === 'FREE_CANCELLATION'),
              isLuggageIncluded: flights.luggage.isIncluded,
            },
          ],
          { list: actionList },
        )
      }
    >
      {t('searchResults.viewDeal')}
    </Button>
  );
};
