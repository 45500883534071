import { Skeleton } from '@loveholidays/design-system';
import { DontHydrate } from '@loveholidays/design-system';
import { AllSystemCSSProperties, ResponsiveStyleValue } from '@styled-system/css';
import React, { Fragment } from 'react';

import { Card } from './Card';
import { ClassNameProps } from '@ComponentProps';
import { useFeatureFlag } from '@Core/octopus/useFeatureFlag';

const skeleton = (
  maxWidth?: ResponsiveStyleValue<AllSystemCSSProperties['maxWidth']>,
  height?: ResponsiveStyleValue<AllSystemCSSProperties['height']>,
) => (
  <Skeleton
    sx={{
      maxWidth,
      width: '100%',
      height,
    }}
  />
);

/**
 * The new SRP card with skeletons indicating it is in its data loading phase.
 *
 * Stand in for the `LoadingCard` component to be used during the `NewSrpCard` experiment.
 * As such, it doesn't take props (other than `className`). Eventually we'll want to accept
 * props and have Aurora pass them such that it matches the template for that site's SRP
 * card.
 */
export const LoadingCard: React.FC<ClassNameProps> = ({ className }) => {
  const freeCancellation = useFeatureFlag('FreeCancellation');

  return (
    <DontHydrate className={className}>
      <Card
        imageArea={
          <Skeleton
            sx={{
              backgroundColor: 'backgroundLightneutral',
            }}
          />
        }
        actionArea={
          <Fragment>
            <Skeleton
              sx={{
                minWidth: 250,
                height: 36,
              }}
            />
          </Fragment>
        }
        headerArea={
          <Fragment>
            {skeleton([240, 299], [24, 32])}
            {skeleton(195, 16)}
            {skeleton(161, 31)}
          </Fragment>
        }
        infoArea={
          <Fragment>
            {skeleton([undefined, 152], 16)}
            {skeleton([undefined, 97], 16)}
            {skeleton([undefined, 141], 16)}
          </Fragment>
        }
        priceArea={
          <Fragment>
            <Skeleton
              sx={{
                width: 50,
                height: 18,
              }}
            />
            <Skeleton
              sx={{
                width: 89,
                height: 32,
              }}
            />
          </Fragment>
        }
        bodyArea={
          freeCancellation ? (
            <div
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '2xs',
                minHeight: 68,
                justifyContent: 'flex-end',
              }}
            >
              <Skeleton
                sx={{
                  minWidth: [190, 150],
                  height: 28,
                }}
              />
              <div
                sx={{
                  display: 'flex',
                  flexDirection: ['column', 'column', 'row'],
                  rowGap: '2xs',
                  columnGap: '3xs',
                }}
              >
                <Skeleton
                  sx={{
                    minWidth: [150, 116],
                    height: 28,
                  }}
                />
                <Skeleton
                  sx={{
                    minWidth: [145, 180],
                    height: 28,
                  }}
                />
              </div>
            </div>
          ) : (
            <Fragment>
              {skeleton(123, 28)}
              {skeleton(116, 28)}
            </Fragment>
          )
        }
      />
    </DontHydrate>
  );
};
