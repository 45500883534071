import { Icon, Label } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React from 'react';

export const MissedItLabel: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Label
      variant="small"
      sx={{
        display: 'flex',
        gap: '3xs',
        alignItems: 'center',
      }}
    >
      <Icon
        name="Markers/Alert"
        size="16"
      />
      <span>{t('missedIt.message')}</span>
    </Label>
  );
};
