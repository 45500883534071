import React from 'react';

import { DepositSash } from './DepositSash';
import { DiscountSash } from './DiscountSash';
import { MissedItSash } from './MissedItSash';
import { NotAvailableSash } from './NotAvailableSash';
import { ComponentName } from './SearchResultCard';
import { SearchResultSash } from './SearchResultSash';
import { DynamicPackageResult } from '@AuroraTypes';
import { usePassengerPrice } from '@Components/Price/usePassengerPrice';

type SearchResultSashesProps = {
  components: Set<ComponentName>;
  offer: DynamicPackageResult;
};

export const SearchResultSashes: React.FC<SearchResultSashesProps> = ({
  components,
  offer: { lowestDeposit, pricing, badges },
}) => {
  const lowestDepositPrice = usePassengerPrice(lowestDeposit);

  return (
    <div
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        rowGap: '2xs',
      }}
    >
      {badges.length !== 0 && (
        <div
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '2xs',
            columnGap: '3xs',
          }}
        >
          {badges.map((badge) => (
            <SearchResultSash
              key={badge.type}
              {...badge}
            />
          ))}
        </div>
      )}
      {(components.has('missed-it') || components.has('unavailable')) && (
        <div
          sx={{
            display: 'flex',
            rowGap: ['2xs', null],
            columnGap: '3xs',
            flexWrap: 'wrap',
          }}
        >
          {components.has('missed-it') && <MissedItSash />}
          {components.has('unavailable') && <NotAvailableSash />}
        </div>
      )}
      <div
        sx={{
          display: 'flex',
          rowGap: ['2xs', null],
          columnGap: '3xs',
          flexWrap: 'wrap',
        }}
      >
        {components.has('pricing') && lowestDepositPrice && (
          <DepositSash lowestDeposit={lowestDepositPrice} />
        )}
        {components.has('pricing') && pricing && <DiscountSash pricing={pricing} />}
      </div>
    </div>
  );
};
