import { useHdpModalUrlUpdate } from '../hotel-details-modal/useHdpModalUrlUpdate';
import { Accommodation, DynamicPackageResult } from '@AuroraTypes';
import { HotelDetailsTabId } from '@Components/HotelDetails/HotelDetailsTypes';
import { useProductDetailsViewTracking } from '@Tracking';

interface UseHotelDetailsModalOpenerProps {
  accommodation: Accommodation;
  offer?: Pick<DynamicPackageResult, 'hotel' | 'flights' | 'pricing' | 'lowestDeposit'>;
}

export type OpenHotelDetails = (props: {
  hotelDetailsTabId?: HotelDetailsTabId;
  eventTriggerName?: string;
}) => () => void;

export const useHotelDetailsModalOpener = ({
  accommodation,
  offer,
}: UseHotelDetailsModalOpenerProps): OpenHotelDetails => {
  const trackProductDetails = useProductDetailsViewTracking();
  const { addUrlHashParams } = useHdpModalUrlUpdate();

  return ({ hotelDetailsTabId, eventTriggerName }) =>
    () => {
      const seoUrl = accommodation.urls.seo;

      addUrlHashParams({
        ...(seoUrl && { hotelUrl: seoUrl }),
        ...(!seoUrl && { hotelMasterId: accommodation.id }),
        ...(hotelDetailsTabId && { hotelTab: hotelDetailsTabId }),
      });

      if (offer) {
        trackProductDetails(
          [
            {
              masterId: accommodation.id,
              name: accommodation.name,
              price: offer.pricing.total,
              uuid: offer.pricing.referral?.uuid,
              xref: offer.pricing.referral?.xref,
              trigger: eventTriggerName,
            },
          ],
          { list: 'SearchResults' },
        );
      }
    };
};
