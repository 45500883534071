import { Heading, Link, StarRating, TriggerButton } from '@loveholidays/design-system';
import React, { Fragment } from 'react';

import { OpenHotelDetails } from './useHotelDetailsModalOpener';
import { Accommodation, Badge, OfferFlights, Pricing, ProductTrackingList } from '@AuroraTypes';
import { ClassNameProps } from '@ComponentProps';
import { useAppContext } from '@Contexts/contexts';
import { useFeatureFlag } from '@Core/octopus/useFeatureFlag';
import { useProductClickTracking } from '@Tracking';

interface HotelNameAndStarsHeadingProps extends ClassNameProps {
  url?: string;
  pricing?: Pick<Pricing, 'total'>;
  accommodation: Pick<Accommodation, 'id' | 'name' | 'starRating'>;
  badges?: Badge[];
  flights?: OfferFlights;
  position: number;
  actionList: ProductTrackingList;
  openHotelDetails: OpenHotelDetails;
}

export const HotelNameAndStarsHeading: React.FC<HotelNameAndStarsHeadingProps> = ({
  accommodation,
  actionList,
  pricing,
  position,
  flights,
  badges,
  className,
  url,
  openHotelDetails,
}) => {
  const isFancyPanda = useFeatureFlag('FancyPanda');
  const trackProductClick = useProductClickTracking();
  const { isWebView } = useAppContext();
  // Disable the linting rule here as we don't want to send the experiment event in app
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const openInNewTab = !isWebView && useFeatureFlag('OpenPandaInNewTab');

  const HotelName = (
    <Heading
      data-id="hotel-name"
      variant={['mediumbold', 'largebold']}
      as="h2"
    >
      <span sx={{ marginRight: '2xs' }}>{accommodation.name}</span>
      <StarRating
        rating={accommodation.starRating}
        sx={{
          display: 'inline-flex',
          verticalAlign: ['baseline', 'middle'],
        }}
      />
    </Heading>
  );

  if (isFancyPanda) {
    return url ? (
      <Link
        target={openInNewTab ? '_blank' : undefined}
        href={url}
        asAnchor
        internal
        sx={{
          color: 'textDefault',
          textDecoration: 'none',
          '&:hover > *': {
            textDecoration: 'underline',
          },
        }}
        className={className}
        onClick={() =>
          trackProductClick(
            [
              {
                masterId: accommodation.id,
                name: accommodation.name,
                price: pricing?.total,
                position,
                refundablePackage: !!badges?.some(({ type }) => type === 'FREE_CANCELLATION'),
                isLuggageIncluded: flights?.luggage?.isIncluded,
              },
            ],
            { list: actionList },
          )
        }
      >
        {HotelName}
      </Link>
    ) : (
      <Fragment>{accommodation.name}</Fragment>
    );
  }

  return (
    <TriggerButton
      className={className}
      onTrigger={openHotelDetails({
        eventTriggerName: 'HotelNameAndStarsHeading',
      })}
      sx={{
        '&:hover > *': {
          textDecoration: 'underline',
        },
      }}
    >
      {HotelName}
    </TriggerButton>
  );
};
