import { BadgeValue } from '@loveholidays/design-system';
import React from 'react';

import { Sash as SashType } from '@AuroraTypes';

interface ImageSashesProps {
  sashes: SashType[];
}

export const ImageSashes: React.FC<ImageSashesProps> = ({ sashes }) => (
  <div
    sx={{
      position: 'absolute',
      left: '3xs',
      top: '3xs',
      display: 'flex',
      flexFlow: 'wrap',
      gap: '4xs',
    }}
  >
    {sashes.map((sash) => (
      <BadgeValue
        category={sash.variant}
        key={sash.label}
      >
        {sash.label}
      </BadgeValue>
    ))}
  </div>
);
