import { Label, TriggerButton } from '@loveholidays/design-system';
import React from 'react';

import { OpenHotelDetails } from './useHotelDetailsModalOpener';
import { Accommodation } from '@AuroraTypes';
import { HotelDetailsTabId } from '@Components/HotelDetails/HotelDetailsTypes';

interface LocationBreadcrumbsLabelProps {
  locationBreadcrumbs: Accommodation['locationBreadcrumbs'];
  distanceFromCityCentre?: string;
  openHotelDetails: OpenHotelDetails;
}

export const LocationBreadcrumbsLabel: React.FC<LocationBreadcrumbsLabelProps> = ({
  locationBreadcrumbs,
  distanceFromCityCentre,
  openHotelDetails,
}) => {
  const breadCrumbsCopy = locationBreadcrumbs.items.map((b) => b.name).join(', ');

  return (
    <Label
      variant="small"
      sx={{
        color: 'textDimmedmedium',
      }}
    >
      {breadCrumbsCopy}
      {distanceFromCityCentre && (
        <React.Fragment>
          {' ∙ '}
          <TriggerButton
            onTrigger={openHotelDetails({
              hotelDetailsTabId: HotelDetailsTabId.map,
              eventTriggerName: 'srp-card-distance-from-city-centre',
            })}
          >
            <Label
              variant="small"
              sx={{
                color: 'textPrimary',
              }}
            >
              {distanceFromCityCentre}
            </Label>
          </TriggerButton>
        </React.Fragment>
      )}
    </Label>
  );
};
