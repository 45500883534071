import { Icon, Label, Tooltip } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React from 'react';

import { OfferHotel } from '@AuroraTypes';
import { useDate } from '@Dates/useDateHook';

type DurationLabelProps = Pick<OfferHotel, 'nights' | 'checkInDate'>;

export const DurationLabel: React.FC<DurationLabelProps> = ({ nights, checkInDate }) => {
  const { t } = useTranslation();
  const { localizedFormat } = useDate();

  return (
    <Tooltip
      content={t('datesShownClarification')}
      sx={{
        display: 'inline-flex',
      }}
    >
      <Label
        variant="small"
        data-id="summary-dates"
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Icon
          name="Content/InputDate"
          size={['20', '16']}
          sx={{
            marginRight: '3xs',
          }}
        />
        {localizedFormat(checkInDate)}
        {' ∙ '}
        {t('nightsLabel', { count: nights })}
      </Label>
    </Tooltip>
  );
};
