import loadable from '@loadable/component';
import { Image } from '@loveholidays/design-system';
import React, { forwardRef, Fragment } from 'react';

import { BoardBasisLabel } from './BoardBasisLabel';
import { Card, imageAreaHeights, imageAreaWidths } from './Card';
import { CheckAvailabilityButton } from './CheckAvailabilityButton';
import { DurationLabel } from './DurationLabel';
import { FlightsIncludedLabel } from './FlightsIncludedLabel';
import { HotelNameAndStarsHeading } from './HotelNameAndStarsHeading';
import { ImageSashes } from './ImageSashes';
import { LocationBreadcrumbsLabel } from './LocationBreadcrumbsLabel';
import { MissedItLabel } from './MissedItLabel';
import { NotAvailableLabel } from './NotAvailableLabel';
import { RatingTrigger } from './RatingTrigger';
import { SearchResultSashes } from './SearchResultSashes';
import { useHotelDetailsModalOpener } from './useHotelDetailsModalOpener';
import { Accommodation, DynamicPackageResult, ProductTrackingList } from '@AuroraTypes';
import { ClassNameProps } from '@ComponentProps';
import { SaveToFavouritesButton } from '@Components/Favourites/SaveToFavouritesButton';
import { useAppContext } from '@Contexts/contexts';
import { useImageFallbackSrc } from '@Core/hooks/useImageFallbackSrc';
import { useFeatureFlag } from '@Core/octopus/useFeatureFlag';
import { Prices } from '@Core/prices/Prices';
import { ImageGallery } from '@UX/Image/ImageGallery';

export type ComponentName =
  | 'hotel-info'
  | 'holiday-summary'
  | 'pricing'
  | 'check-availability-button'
  | 'unavailable'
  | 'missed-it';

interface SearchResultCardProps extends ClassNameProps {
  accommodation: Accommodation;
  position: number;
  offer?: DynamicPackageResult;
  components?: ComponentName[];
  pandaUrl?: string;
  lazyImage?: boolean;
  isFamily?: boolean;
  actionList?: ProductTrackingList;
  favouritesListId?: string;
  isFavouritesReadOnly?: boolean;
}

export const SearchResultCard = forwardRef<HTMLDivElement, SearchResultCardProps>(
  (
    {
      components: componentsArray = [
        'hotel-info',
        'holiday-summary',
        'pricing',
        'check-availability-button',
      ],
      accommodation,
      offer,
      className,
      pandaUrl,
      isFamily = false,
      lazyImage = true,
      'data-id': dataId = 'search-result-card',
      position,
      actionList = 'SearchResults',
      favouritesListId,
      isFavouritesReadOnly,
    },
    ref,
  ) => {
    const { isWebView } = useAppContext();
    const fallbackImageSrc = useImageFallbackSrc();

    // Get feature flags
    const isWebViewFavouritesEnabled = useFeatureFlag('WebViewFavourites');
    const isFavouritesEnabled =
      useFeatureFlag('Favourites') &&
      (!isWebView || isWebViewFavouritesEnabled) &&
      !isFavouritesReadOnly;

    const srpScrollingGallery = useFeatureFlag<'all' | 'few' | false>('SRPScrollingGallery');
    const ScrollingGallery = loadable(() => import('@UX/ScrollingGallery/ScrollingGallery'), {
      resolveComponent: (module) => module.ScrollingGallery,
    });

    // Destructure accommodation
    const { images, name, sashes, starRating, reviewCount, reviewRating, ratings, distancesToPOI } =
      accommodation;
    const adultOrFamilySashes = isFamily ? sashes.family : sashes.adult;
    const distanceFromCityCentre = distancesToPOI?.find(
      ({ reference }) => reference === 'CITY_CENTRE',
    )?.description;

    const components = new Set(componentsArray);
    const openHotelDetails = useHotelDetailsModalOpener({ accommodation, offer });

    return (
      <Card
        data-id={dataId}
        ref={ref}
        className={className}
        subActionArea={
          isFavouritesEnabled ? (
            <SaveToFavouritesButton
              masterId={Number(accommodation.id)}
              favouritesListId={favouritesListId}
              hotelInfo={{
                name,
                image: images.items[0],
                starRating,
                reviewCount,
                reviewRating,
              }}
            />
          ) : null
        }
        imageArea={
          <Fragment>
            {images.items.length <= 1 && (
              <Image
                src={images.items[0]?.url || fallbackImageSrc}
                alt={images.items[0]?.description}
                width={imageAreaWidths}
                height={imageAreaHeights}
                lazy={lazyImage}
                quality={70}
              />
            )}
            {images.items.length > 1 && srpScrollingGallery === false && (
              <ImageGallery
                images={images.items}
                width={imageAreaWidths}
                height={imageAreaHeights}
                lazyImage={lazyImage}
                showFullScreenButton={false}
                trackingObject={{
                  masterId: accommodation.id,
                  name: accommodation.name,
                }}
                name={name}
              />
            )}
            {images.items.length > 1 && !!srpScrollingGallery && (
              <ScrollingGallery
                name={accommodation.name}
                showImages={srpScrollingGallery}
                images={images.items}
                width={imageAreaWidths}
                height={imageAreaHeights}
                lazyImage={lazyImage}
                showFullScreenButton={false}
                trackingObject={{
                  masterId: accommodation.id,
                  name: accommodation.name,
                }}
              />
            )}

            {!!adultOrFamilySashes.length && <ImageSashes sashes={adultOrFamilySashes} />}
          </Fragment>
        }
        headerArea={
          <Fragment>
            <HotelNameAndStarsHeading
              accommodation={accommodation}
              url={pandaUrl}
              actionList={actionList}
              pricing={offer?.pricing}
              position={position}
              flights={offer?.flights}
              openHotelDetails={openHotelDetails}
            />
            {components.has('hotel-info') && (
              <LocationBreadcrumbsLabel
                locationBreadcrumbs={accommodation.locationBreadcrumbs}
                openHotelDetails={openHotelDetails}
                distanceFromCityCentre={distanceFromCityCentre}
              />
            )}
            {components.has('hotel-info') && (
              <div
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '2xs',
                  flexWrap: 'wrap',
                  marginY: '4xs',
                }}
              >
                {ratings.length > 0 &&
                  ratings.map((rating) => (
                    <RatingTrigger
                      key={rating.id}
                      provider={rating.provider}
                      recommendation={rating.recommendation}
                      openHotelDetails={openHotelDetails}
                      reviewCount={rating.ratingCount}
                      rating={rating.rating}
                      variant="inline"
                    />
                  ))}
              </div>
            )}
          </Fragment>
        }
        bodyArea={
          components &&
          offer && (
            <SearchResultSashes
              components={components}
              offer={offer}
            />
          )
        }
        infoArea={
          <Fragment>
            {components.has('holiday-summary') && offer && (
              <Fragment>
                <DurationLabel
                  nights={offer.hotel.nights}
                  checkInDate={offer.hotel.checkInDate}
                />
                <BoardBasisLabel boardBasisCode={offer.hotel.boardBasisCode} />
                <FlightsIncludedLabel
                  isOpenJaw={offer.flights.isOpenJaw}
                  inbound={offer.flights.inbound}
                  outbound={offer.flights.outbound}
                  alternativeFlightsUrl={`${pandaUrl}&alternativeFlights=true&ref=srp-flight-included`}
                  hotelMasterId={accommodation.id}
                  hotelName={accommodation.name}
                />
              </Fragment>
            )}
            {components.has('missed-it') && <MissedItLabel />}
            {components.has('unavailable') && <NotAvailableLabel />}
          </Fragment>
        }
        actionArea={
          <Fragment>
            {components.has('check-availability-button') && !!pandaUrl && !!offer && (
              <CheckAvailabilityButton
                accommodation={accommodation}
                pricing={offer.pricing}
                url={pandaUrl}
                position={position}
                actionList={actionList}
                badges={offer.badges}
                flights={offer.flights}
              />
            )}
          </Fragment>
        }
        priceArea={components.has('pricing') && !!offer && <Prices pricing={offer.pricing} />}
      />
    );
  },
);
