import { Sash } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React from 'react';

export const MissedItSash: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Sash
      variant="Promotion"
      data-id="missed-it"
      sx={{
        letterSpacing: '-0.04em',
        '> span': {
          fontWeight: 'normal',
        },
        whiteSpace: 'nowrap',
      }}
    >
      {t('missedIt.title')}
    </Sash>
  );
};
