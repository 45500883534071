import { Icon, Label, Tooltip } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React from 'react';

import { OfferHotel } from '@AuroraTypes';

type BoardBasisLabelProps = Pick<OfferHotel, 'boardBasisCode'>;

export const BoardBasisLabel: React.FC<BoardBasisLabelProps> = ({ boardBasisCode }) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      content={t('boardBasisClarification')}
      sx={{
        display: 'inline-flex',
      }}
    >
      <Label
        variant="small"
        data-id="summary-board-basis"
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Icon
          name="Content/FoodBoard"
          size={['20', '16']}
          sx={{
            marginRight: '3xs',
          }}
        />
        {t('boardBasis.boardBasis', { context: boardBasisCode })}
      </Label>
    </Tooltip>
  );
};
